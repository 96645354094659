import { useMutation, useQuery } from "@tanstack/react-query";
import { COMMITMENT } from "services";

export const useCommitmentList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return COMMITMENT.getProposalListCommitment(id, signal);
    },
    ...options
  });
};

export const useCommitmentDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return COMMITMENT.getProposalDetailCommitment(id, signal);
    },
    ...options
  });
};

export const usePostCommitment = () => {
  return useMutation((payload) => COMMITMENT.SubmitCommitment(payload))
}

export const usePostDraftCommitment = () => {
  return useMutation((payload) => COMMITMENT.DraftCommitment(payload))
}